<template>
  <div class="bg-white">
    <div class="team-member-wrap">
      <!-- 搜索框开始 -->
      <div class="search-wrap align-center">
        <div class="search-box">
          <img src="~assets/images/public/search-icon.png" class="search-icon"/>
          <form action="javascript:void 0" style="width: 100%;height: 100%">
            <input type="text" placeholder="搜索队员" maxlength="20" @keyup.enter="searchConfirm" v-model="search_inp"
                   class="search-inp"/>
          </form>
          <img src="~assets/images/public/search-cancel-icon.png" @click="cancelSearch" class="search-cancel-icon"
               v-if="search_inp.trim().length > 0" alt="">
        </div>
        <div class="search-btn" @click="searchConfirm">搜索</div>
      </div>
      <!-- 搜索框结束 -->

      <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="teamData.length > 10 ? '没有更多了': ''"
          :immediate-check="false"
          @load="onLoad"
      >
        <van-checkbox-group v-model="result" checked-color="#FF6B29" @change="checkboxFun" ref="checkboxGroup">
          <van-checkbox :name="item.wechat ? item.wechat.user_id : null" v-for="(item, index) in teamData" :key="index" :disabled="item.is_group_team_master == 1">
            <div class="team-member-item vertical-center">
              <img
                  :src="item.wechat && item.wechat.avatar != '' ? item.wechat.avatar : 'https://static.helixlife.cn/edu/home/images/avatar.png'"
                  class="user-avatar" alt="">
              <div class="item-r">
                <div class="item-r-top vertical-center">
                  <div class="item-userInfo flex vertical-center">
                    <p class="name white-space">{{ item.wechat ? item.wechat.nickname : '酸谈社群' }}</p>
                    <div class="serial-number">编号:{{ item.number }}</div>
                    <div class="user-identity align-center" v-if="item.is_group_team_master == 1">
                      <p>小队长</p>
                    </div>
                  </div>
                  <img src="~assets/images/user/team-edit-icon.png" @click.stop="editFun(item, index)" class="edit-icon"
                       alt="">
                </div>
                <div class="item-r-info vertical-center">
                  <p class="integral-leave">Lv{{ item.rule ? item.rule.detail.name : 0 }}</p>
                  <div class="experience-num vertical-center">
                    <img src="~assets/images/user/team-experience-icon.png" alt="">

                    <p>{{ item.account ? $handle.tranNumber(item.account.experience, 3) : 0 }}</p>
                  </div>
                </div>
              </div>
              <!--            <img src="~assets/images/user/team-edit-icon.png" class="edit-icon" alt="">-->
            </div>
          </van-checkbox>
        </van-checkbox-group>
        <no-data v-if="teamData.length == 0 && list_status"></no-data>
      </van-list>
    </div>

    <!--编辑小队成员开始-->
    <van-popup
        v-model:show="edit_show"
        :safe-area-inset-bottom="true"
        round
        position="bottom"
    >
      <div class="edit-team-member-pop">
        <div class="edit-team-btn-item" @click="captainEdit" v-if="type==null">
          {{ current_data.is_group_team_master == 1 ? '取消小队长' : '设为小队长' }}
        </div>
        <div class="edit-team-btn-item" @click="moveTeam">移动小队</div>
        <div class="edit-team-btn-item" @click="edit_show=false">取消</div>
      </div>
    </van-popup>
    <!--编辑小队成员结束-->

    <!--底部编辑开始-->
    <div class="bottom-btn-height safety-height"></div>
    <div class="edit-bottom-fixed safety-height" v-if="teamData.length > 0">
      <div class="edit-bottom-cont space-between">
        <div class="check-all align-center">
          <van-checkbox v-model="checkboxAll" checked-color="#FF6B29" @click="checkAll">全选</van-checkbox>
        </div>
        <div class="edit-btn" :class="{'edit-btn-default': result.length == 0}" @click="switchTeams">转队</div>
      </div>
    </div>
    <!--底部编辑结束-->

    <van-popup v-model:show="change_team"
               round
               position="bottom"
               :safe-area-inset-bottom="true"
    >
      <van-picker
          :columns="TeamTags"
          @confirm="onConfirm"
          @cancel="change_team = false"
          :columns-field-names="customFieldName"
      />
    </van-popup>

    <!-- 加载提示   -->
    <van-overlay :show="login_loading_show" z-index="9999">
      <div class="lodding-wrap">
        <van-loading type="spinner"/>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import NoData from "@/components/NoData";
import _ from 'lodash'

export default {
  name: "Team",
  data() {
    return {
      result: [],   //队员选择
      id: '', // 分队id
      page: 1,
      loading: false,
      finished: false,
      teamData: [],    // 战队列表
      edit_show: false, // 编辑队员弹框
      current_data: null, //当前点击用户数据
      current_index: -1,
      checkboxAll: false, //  全选状态
      TeamTags: [], //小队列表
      change_team: false, // 选择小队弹框
      customFieldName: {
        text: 'name'
      },
      team_group_id: '', // 转对小队id
      team_group_type: null,  // 转对操作类型
      list_status: false,
      type: null,
      login_loading_show: false, // 加载提示
      search_inp: '', // 搜索值
    }
  },
  created() {
    if (this.userInfo && this.userInfo.is_team_master != 1) {
      this.$router.replace('/user')
    }
    if (this.$route.query.type) {
      this.type = this.$route.query.type
    }
    this.id = this.$route.params.id
    this.getTeamDetail()
    this.getTeam()
    this.getTeamTags()

  },
  watch: {
    teamData(old, new_val) {
      if (new_val.length != this.result.length) {
        this.checkboxAll = false
      }
    }
  },
  methods: {
    // 获取小队成员
    getTeam() {
      let url = this.$api.userNembers + '/' + this.id + '?page=' + this.page
      this.list_status = false
      this.$http.get(url, true).then(res => {
        if (res.data.success) {
          let _data = res.data.data.data
          this.page++
          this.loading = false;
          if (!_data.length) {
            this.finished = true;

          }
          this.teamData = this.teamData.concat(_data)
          setTimeout(() => {
            this.list_status = true
          }, 200)
        }
      })
    },
    // 获取小队详情
    getTeamDetail() {
      let url = this.$api.TeamGroups + '/' + this.id
      this.$http.get(url, true).then(res => {
        if (res.data.success) {
          this.$store.commit('M_TITLE', res.data.data.name)
        }
      })
    },
    // 列表设置默认
    teamDefault() {
      this.finished = false;
      this.loading = false;
      this.page = 1
      this.teamData = []
      this.result = []
      this.checkboxAll = false
    },
    // 转对设置
    switchTeams() {
      if (this.result.length > 0) {
        this.team_group_type = 'multiple'
        this.change_team = true
      }
    },
    // 编辑队员
    editFun(item, index) {
      this.edit_show = true;
      this.current_index = index
      this.current_data = item
    },
    // 队长和非队长编辑
    captainEdit: _.debounce(function () {
      this.edit_show = false;
      let type = -1
      if (this.current_data.is_group_team_master == 1) {
        type = 2
      } else {
        type = 1
      }
      this.setFun(type)
    }, 500),
    setFun(type) {
      let url = this.$api.teamSet
      let data = {
        type: type,
      }
      if (type == 3) {
        data.team_group_id = this.team_group_id
      }
      // 判断是批量操作还是单个
      if (this.team_group_type == 'multiple') {
        data.user_id = this.result.toString()
      } else {
        data.user_id = this.current_data.user_id
      }
      this.$http.post(url, data, true).then(res => {
        if (res.data.success) {

          this.teamDefault()
          this.getTeam()
          setTimeout(() => {
            this.login_loading_show = false
            this.$toast('设置成功')
          }, 200)
        } else {
          this.$toast(res.data.message)
          this.login_loading_show = false
        }
      }).catch((error => {
        this.login_loading_show = false
      }))
    },
    // 全选点击
    checkAll() {
      const {checkboxGroup} = this.$refs
      if (this.checkboxAll) {
        checkboxGroup.toggleAll(true)
        if(this.teamData[0].is_group_team_master == 1){
          this.result.shift()
        }
      } else {
        checkboxGroup.toggleAll(false)
      }
    },
    // 选择方法
    checkboxFun(value) {
      if ((this.result.length === this.teamData.length || this.result.length === this.teamData.length - 1) && this.teamData.length > 0) {
        this.checkboxAll = true
      } else {
        this.checkboxAll = false
      }
    },
    // 选择小队确认
    onConfirm(value) {
      this.change_team = false
      this.team_group_id = value.id
      this.login_loading_show = true
      this.setFun(3)
    },
    // 获取小队名称列表
    getTeamTags() {
      let url = this.$api.teamTags
      this.$http.get(url, true).then(res => {
        if (res.data.success) {
          this.TeamTags = res.data.data
        }
      })
    },
    // 转移小队
    moveTeam() {
      this.team_group_type = 'single'
      this.edit_show = false
      this.change_team = true
    },
    // 底部加载更多
    onLoad() {
      if (this.list_status && this.teamData.length > 8) {
        this.getTeam()
      }
    },
    // 搜索事件
    searchConfirm() {
      if (this.search_inp.trim().length == 0) {
        return false
      }
      let url = this.$api.userMembers + '/' + this.id + '?type=1&page=1&keywords=' + this.search_inp;
      this.$http.get(url, true).then(res => {
        if (res.data.success) {
          let _data = res.data.data.data
          this.result = []
          this.checkboxAll = false
          if (_data) {
            this.teamData = _data
            this.finished = true;
          } else {
            this.teamData = []
          }
        } else {
          this.$toast(res.data.message)
        }
      })
    },
    // 取消搜索事件
    cancelSearch() {
      this.search_inp = ''
      this.teamDefault()
      this.getTeam()
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  components: {
    NoData
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background:#F7F7F7')
  },
  beforeUnmount() {
    document.querySelector('body').setAttribute('style', 'background:#FFFFFF')
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/team-management/team-member.scss";

.lodding-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
<style>
.van-picker__confirm {
  color: #FD6A29;
}

.van-picker-column__item {
  color: #0C0C0C;
}

.edit-btn-default {
  background: #BFBFBF !important;
}
</style>
